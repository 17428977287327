import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'
import ParallaxSection from "@components/parallaxSection"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

import { Helmet } from 'react-helmet';

const SchemaOrg = () => {
	const schema = [{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "The Websuasion Group LLC",
  "url": "https://websuasion.com/",
  "logo": "https://websuasion.com/images/websuasion-full-logo.png",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1-404-418-8909",
    "contactType": "Customer Service",
    "areaServed": "US",
    "availableLanguage": "English",
    "contactOption": "TollFree",
    "email": "sales@websuasion.com"
  },
  "sameAs": [
    "https://www.linkedin.com/company/websuasion",
    "https://www.youtube.com/@websuasion",
    "https://www.instagram.com/websuasion/",
    "https://www.threads.net/@websuasion",
    "https://twitter.com/websuasion",
    "https://www.tiktok.com/@leadarchitect",
    "https://www.facebook.com/websuasion"
  ],
  "description": "Websuasion is a fractional, white-label marketing agency offering data-driven strategies, advanced technologies, and a team of experienced specialists to deliver comprehensive digital marketing services.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "PO BOX 142395",
    "addressLocality": "Fayetteville",
    "addressRegion": "GA",
    "postalCode": "30214-9998",
    "addressCountry": "US"
  },
  "founder": {
    "@type": "Person",
    "name": "J. Ryan Williams"
  },
  "employee": [
    {
      "@type": "Person",
      "name": "J. Ryan Williams",
      "jobTitle": "Fractional Chief Marketing Officer",
      "worksFor": {
        "@type": "Organization",
        "name": "The Websuasion Group LLC"
      }
    }
  ],
  "makesOffer": [
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "White Label Marketing",
        "serviceType": "White Label Marketing",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Fractional Marketing",
        "serviceType": "Fractional Marketing",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Fractional CMO",
        "serviceType": "Fractional Chief Marketing Officer",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Digital Marketing Strategy",
        "serviceType": "Digital Marketing Strategy",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "SEO",
        "serviceType": "Search Engine Optimization",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Content Marketing",
        "serviceType": "Content Marketing",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Content Creation",
        "serviceType": "Content Creation",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Inbound Marketing",
        "serviceType": "Inbound Marketing",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Lead Generation",
        "serviceType": "Lead Generation",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Web Design",
        "serviceType": "Web Design",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "PPC Management",
        "serviceType": "Pay Per Click Management",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Web Development",
        "serviceType": "Custom Web Application Development",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Video Production",
        "serviceType": "Video Production",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    }
  ]
},
{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "Digital Marketing Agency Atlanta",
  "author": {
    "@type": "Person",
    "name": "J. Ryan Williams"
  },
  "datePublished": "2024-05-12",
	"dateModified" : "2024-07-26",
  "publisher": {
    "@type": "Organization",
    "name": "The Websuasion Group LLC", 
    "url": "https://websuasion.com",
    "logo": {
      "@type": "ImageObject",
      "url": "https://websuasion.com/images/websuasion-full-logo.png"
    }
  },
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://websuasion.com/digital-marketing-agency-atlanta/"
  },
  "image": "https://websuasion.com/images/atlanta-digital-marketing-agency.jpg",
  "articleSection": "Atlanta Based Team",
  "description": "Websuasion is a digital marketing agency in Atlanta, providing data-driven marketing strategies, SEO, PPC management, content creation, and web development services.",
  "keywords": ["Digital Marketing Agency Atlanta", "Digital Marketing Services", "Keyword Research", "Content Strategy Development", "Content Plan Development", "Web Design", "Web Development", "App Developmemt", "Managed Web Hosting", "Marketing Funnel Development", "Content Writing", "Video Production", "Search Engine Optimization", "SEO", "Local SEO", "Technical SEO", "Link Building", "Social Media Marketing", "Pay Per Click Management", "PPC Management", "Conversion Rate Optimization", "CRO", "Email Marketing", "Campaign Tracking", "Marketing Analytics"],
  "hasPart": [
    {
      "@type": "VideoObject",
      "name": "Atlanta's Digital Marketing Agency - Fractional and White-Label - Websuasion",
      "description": "Websuasion is a digital marketing agency providing our customers Data, Tech, and Team. Research drives our strategy. We use existing technology and develop custom solutions. And our Atlanta-based professional team delivers experience and creative intuition.",
      "thumbnailUrl": "https://i.ytimg.com/vi/y8cuhnDNyWY/maxresdefault.jpg",
      "uploadDate": "2024-05-17T08:26:00-07:00",
      "duration": "PT9M22S",
      "contentUrl": "https://www.youtube.com/watch?v=y8cuhnDNyWY",
      "embedUrl": "https://www.youtube.com/embed/y8cuhnDNyWY"
    },
    {
      "@type": "VideoObject",
      "name": "Contacting Websuasion for an Introductory Meeting",
      "description": "At Websuasion, our motto is: Data. Tech. Team. Do the research. Harness the technology. But ultimately, success comes down to human decisions. We hope to get to know you and your business soon.",
      "thumbnailUrl": "https://i.ytimg.com/vi/-YTR647HTBM/maxresdefault.jpg",
      "uploadDate": "2024-05-16T10:07:01-07:00",
      "duration": "PT1M9S",
      "contentUrl": "https://www.youtube.com/watch?v=-YTR647HTBM",
      "embedUrl": "https://www.youtube.com/embed/-YTR647HTBM"
    }
  ]
}];
	
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

const IndexPage = () => {
  return (
    <PageLayout>
			<SchemaOrg />
      <SEO
        title="Digital Marketing Agency Atlanta - Contacting Websuasion"
        ogDescription="Contact Websuasion, a leading Atlanta digital marketing agency, for expert SEO, web design, content creation, and custom tech solutions."
        image="/images/atlanta-digital-marketing-agency.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Digital Marketing Agency Atlanta: Contacting Websuasion</h1>
            <p>
							Websuasion is a digital marketing agency providing our customers Data, Tech, and Team. Research drives our strategy. We use existing technology and develop custom solutions. And our Atlanta-based professional team delivers experience and creative intuition.
            </p>
            <ul>
							<li>Data-Driven SEO Strategy</li>
							<li>Marketing Website Design</li>
							<li>Content Marketing</li>
							<li>Video Production</li>
							<li>Social Media Management</li>
							<li>Pay Per Click Campaign Management</li>
							<li>Marketing Platform Integration and Custom App Development</li>
						</ul>
          </div>
        }
        textRight={
		      <div className='player-wrapper'>
		        <ReactPlayer
		          className='react-player'
		          url='https://www.youtube.com/watch?v=y8cuhnDNyWY'
		          width='100%'
		          height='100%'
		        />
		      </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								The booming entertainment and technology scene in Atlanta makes it a marketing hub. We know there are several top digital marketing agencies in Atlanta to work with. It's important to us that our unique approach aligns with your business goals.
							</p>
		          <p>
								We welcome the opportunity to drive qualified leads to your products and services. Get in touch, and we'll schedule a meeting to see if we make a good match.
		          </p>
		          <FormWrapper
		            modal
		            buttonProps={{
		              content: "Schedule An Introductory Call",
		              size: "tiny",
		              icon: "calendar alternate outline",
		            }}
		          >
		            <RequestMoreInfo />
		          </FormWrapper>
	          </div>
	        }
	      />
		</PageWrapper>
    <PageWrapper color="violet">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/atlanta-digital-marketing-agency.jpg" className="rounded-image" alt="Marketing Technology - Automated Lead Funnels - Websuasion" />
          </div>
        }
        textRight={
          <div>
            <h2>Atlanta Based Team</h2>
						<p>
						One of the things our clients appreciate is that our team lives in the metro Atlanta area. We are happy to work with clients nationwide, but most of our clients also call Atlanta home.
						</p>
						<h3>No Overseas Outsourcing</h3>
						<p>
						We have an <a href="/fractional-marketing-team/">extensive network of professionals</a> all over the country for specializations. But we rarely outsource. And in the rare situations that we do, we don't outsource overseas. Keeping things local helps us to maintain quality, deadlines, and accountability.
						</p>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper color="rhodamine">
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h2>Marketing Website Design</h2>
						<p>
							Should your website be a brochure or a lead generation system? At Websuasion, our answer is lead generation. With only rare exceptions, each page on your website should drive qualified leads. If it doesn't, what's the point of it?
						</p>
						<h3>Is Your Website A Glorified Branding Asset?</h3>
						<p>
							Most websites start as a glorified branding asset - a digital brochure. Businesses wonder why they aren't getting search traffic. It's because they did not design the content to rank. At best, SEO optimization is shoe-horned in as an afterthought - which will limit success. 
						</p>
						<h3>Fancy Isn't What Gets Search Traffic</h3>
						<p>
							You should design an attractive and engaging website. But to reach prospects and convert them to customers, you must build from a content strategy. A no-compromise content-driven website will beat the fanciest award-winning web design. Every. Single. Time. 
						</p>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper color="orange">
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h2>Data-Driven SEO Strategy</h2>
						<p>
							Data-driven SEO starts with <a href="/content-marketing-strategy/">comprehensive research and analysis</a> to develop a content plan. At Websuasion, we use 26 data points to scrutinize each potential keyword phrase. This data becomes the basis for your website content and influences your messaging for your entire organization. It influences your digital advertising, email marketing, and even print assets. 
						</p>
						<h3>Data Eliminates Guessing</h3>
						<p>
							Knowing <a href="/content-marketing-strategy/keywords-for-marketing/">keyword effectiveness</a>, user behavior, and competitor strategies gives your organization power. You no longer throw spaghetti at the wall to see what sticks. Data allows us to target your messaging where it matters. Research and strategy take the guesswork out of an effective online presence.
						</p>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper color="white">
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h2>Content Marketing</h2>
						<p>
							Content marketing is about distributing valuable, relevant content to your ideal customer. Formats will include articles, infographics, videos, photos, status updates, and more. Content marketing builds brand authority, fosters trust and drives customer action. Working with a digital marketing agency laser-focused on content development is critical.
						</p>
						<h3>Inform. Entertain. Build Trust. Grow Sales.</h3>
						<p>
							The key is "valuable information." Successful marketing content that persists is infotainment. Don't get caught up in the desire to go viral. That's temporary and broad. Your ideal customers have specific pain points. Your content should address that pain and offer your solutions. Search and social media platforms will reward you with positioning. Users will reward you by clicking your calls to action.
						</p>
		        <FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
      />
    </PageWrapper>
    <ParallaxSection
      src={`/images/digital-marketing-atlanta-video-editing.jpg`}
    />
    <PageWrapper color="white">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/digital-marketing-atlanta-video-production.webp" className="rounded-image" alt="Digital Marketing Agency Atlanta Video Production - Websuasion" />
          </div>
        }
        textRight={
          <div>
						<h2>Video Production</h2>
						<p>
							If content is king, then video is the emperor. There is no better way to connect with a prospect than through video. And these days, <a href="/atlanta-video-production/">creating engaging videos</a> is cost-effective. We have over 25 years of video production experience. We've aligned that production background with two core principles:
						</p>
						<ol>
							<li> Our research drives your content</li>
							<li>We repurpose your content across all relevant formats</li>
						</ol>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<p>
							These principles mean that all the work that goes into building a search-ranking web page continues to be valuable. That content becomes scripts for both long and short-form videos.
						</p>
						<h3>Our place. Your place. Or Do-It-Yourself.</h3>
						<p>
							We offer professional cameras, audio, and lights at your location or ours.  We also set many clients up with quality gear and training packages so they can film on their schedules to their hearts' content. They upload the footage to us, and we take it from there.
						</p>
						<p>
							Whether working from your footage or ours, we excel at post-production: seamless editing, color correction, audio sweetening, and motion graphics. We make you look good and format it all for the various platforms. We even handle distribution and scheduling.
						</p>
						<p>
							There are many uses for video:
						</p>
						<ul>
							<li>Automated Webinars</li>
							<li>Long-Format YouTube Videos</li>
							<li>Short-Format Social Media Videos</li>
							<li>PPC Ad Campaign Content</li>
							<li>Explaining Your Services</li>
							<li>Product Demonstrations</li>
							<li>Customer Testimonials</li>
							<li>Case Studies</li>
							<li>Training</li>
						</ul>
						<p>
							Websuasion is one of Atlanta's few digital marketing agencies specializing in creating SEO videos. We look forward to discussing how video can work for you.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper color="charcole">
      <SingleColumnBlock
        text={
          <div>
						<h2>Social Media Management</h2>
						<p>
						Social media management is planning, creating, scheduling, and analyzing content for social media platforms. We generally focus on Facebook, Instagram, Threads, TikTok, and LinkedIn. We develop and implement a strategy to achieve your specific business goals. Social media can be effective for:
						</p>
						<ul>
							<li>Sales Prospecting</li>
							<li>Partner Connections</li>
							<li>Email List-Building</li>
							<li>YouTube Channel Subscription</li>
							<li>and much more</li>
						</ul>
						<p>
							We will build a custom plan for managing the social media activity your business needs.
						</p>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper color="white">
      <SingleColumnBlock
        text={
          <div>
						<h3>Pay Per Click Campaign Management</h3>
						<p>
							Pay-per-click (PPC) campaign management oversees and optimizes paid advertising campaigns. We work with Google AdWords, Facebook, TikTok, and LinkedIn. We set up targeted ads around our keyword research. We manage ad spend budgets and track the performance of your ad campaigns. We make real-time adjustments, ensuring the most cost-effective and results-driven advertising approach. 
						</p>
						<h3>Transparency With Your PPC Budget</h3>
						<p>
							Our billing is hourly and transparent. We are not the middleman between you and the ad platform. The platforms bill you for the ads. Our fees cover only our time. No markup. There are no questions about where the money is going.
						</p>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper color="rhodamine">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/digital-marketing-atlanta-application-developer.webp" className="rounded-image" alt="Application Developer - Digtal Marketing Atlanta - Websuasion" />
          </div>
        }
        textRight={
          <div>
            <h2>Marketing Platform Integration and Custom App Development</h2>
						<p>
							We have over 25 years of full-stack web development experience. Look at our case studies. You can see the level of our work for businesses in wide-ranging industries.
						</p>
						<p>
							When an existing web software solution fills your needs, we are happy to use it. But often, businesses will have specialized requirements beyond even the most robust systems.
						</p>
						<h3>Do You Live Inside Spreadsheets?</h3>
						<p>
							Where do you first go when available software solutions only provide some of the tools you need? Excel. Google Sheets. Apple Numbers. We see it over and over again. Spreadsheets are great for simple tasks. But once things get complicated, you become a prisoner of the spreadsheet. They move slower and slower as you add data. It's all too easy to make mistakes than create a series of repercussions. It's challenging to train someone as a backup. So, good luck going on vacation without logging in to manage things.
						</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h3>Custom Web Apps To The Rescue</h3>
						<p>
							You should talk to us about custom web app development if you can relate. We can look at the API integration capabilities of your current systems. We will model a system based on the spreadsheets you built. And we will develop a secure, reliable, trainable, time-saving custom solution.
						</p>
						<p>
							Here are some examples of business problems we solve:
						</p>
						<ul>
							<li>Payroll Calculation for Sales and Labor</li>
							<li>Financial Reporting for C-Suite Executives</li>
							<li>Job Estimation and Tracking</li>
							<li>Research Tools for Medical Studies</li>
							<li>Customer Relationship Management</li>
							<li>Customer Service Portals</li>
							<li>Customer Portals</li>
							<li>Operations Portals</li>
							<li>Software as a Service Platforms</li>
						</ul>
						<p>
							If there is an API, we can talk to it. If there is a business need no one is filling, we can code for it.
						</p>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper color="white">				
			<TextBlock
        textPadded
        textLeft={
          <div>
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/watch?v=-YTR647HTBM'
			          width='100%'
			          height='100%'
			        />
						</div>
          </div>
        }
        textRight={
          <div>
            <h4>Let's Talk!</h4>
            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <RequestMoreInfo />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h4>Featured On</h4>
						<p>
							<a href="https://www.ontoplist.com/marketing-agencies/ga/atlanta/" target="_blank"><img src="https://www.ontoplist.com/images/ontoplist31.png?id=66b37192ba179" alt="Top Digital Marketing Agencies In Atlanta - OnToplist.com" border="0" /></a>
						</p>
						<p>
							<a href="https://www.abnewswire.com/companyname/websuasion.com_140145.html"><img src="/images/abnewswirelogo.png" /></a>
						</p>
          </div>
        }
      />
		</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage